import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "@/assets/css/reset.scss";
import "@/assets/font/font.css";

import Vue2OrgTree from "vue2-org-tree";
import "vue2-org-tree/dist/style.css";
Vue.use(Vue2OrgTree);

// 全局引入Fragment组件
import Fragment from "vue-fragment";
Vue.use(Fragment.Plugin);

import "@/assets/css/reset.scss";
import waterfall from "vue-waterfall2";
Vue.use(waterfall);

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI);

import { fittingString } from "@/utils/aboutFont.js";
import mavonEditor from "mavon-editor";
Vue.use(mavonEditor);
Vue.prototype.$fittingString = fittingString;

import videojs from "video.js";
import "video.js/dist/video-js.css";
Vue.prototype.$video = videojs;

import utils from "@/utils/util";
// 引入大屏屏幕适配解决方案
import VScaleScreen from 'v-scale-screen'
Vue.use(VScaleScreen)

Vue.mixin({
  data() {
    return {};
  },
  methods: {
    ...utils,
  },
});

import weui from "weui.js";
import "weui";
Vue.prototype.$weui = weui;

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
  beforeCreate() {
    Vue.prototype.$bus = this;
  },
}).$mount("#app");
