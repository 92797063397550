import axios from "axios";
import { _cookie } from "../../utils/token";
import { Message } from "element-ui";
import router from "@/router";

console.log('当前环境', process.env.NODE_ENV);

const isAnswer = window.location.hash.includes('answer-question');
console.log('是否问答页面', isAnswer);

const service = axios.create({
	// 本机环境
	baseURL: process.env.VUE_APP_API,
	timeout: 15000,
});
service.interceptors.request.use(
	(config) => {
		config.headers["Authorization"] = `Bearer ${
			_cookie.getCookie("token") ? _cookie.getCookie("token") : "null"
		}`;
		return config;
	},
	(err) => {
		return Promise.reject(err);
	}
);
service.interceptors.response.use(
	(response) => {
		if (response.data.code === 403) {
			// Message.warning(`${response.data.msg},即将重新登陆`);
			// 弹窗
			// setTimeout(() => {
			// 	router.replace("/login");
			// 	// 删除所有cookie
			// 	_cookie.clearAllCookies();
			// }, 1500);
			// _cookie.setCookie("noLogin", "true");
		}
		return response;
	},
	(error) => {
		return Promise.reject(error.response);
	}
);

export default service;
