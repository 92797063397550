import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
	{
		// 登录
		path: "/login",
		name: "login",
		component: (resove) => require(["../views/login/login.vue"], resove),
	},
	{
		// 注册
		path: "/register",
		name: "register",
		component: (resove) => require(["../views/login/register.vue"], resove),
	},
	{
		// 绑定企业
		path: "/bindEnterprise",
		name: "bindEnterprise",
		component: (resove) =>
			require([
				"../views/userControl/individualUser/bindEnterprise.vue",
			], resove),
	},
	// {
	// 	// 产业驾驶舱
	// 	path: "/application/enterpriseCockpitForIndustry",
	// 	name: "enterpriseCockpitForIndustry",
	// 	component: (resove) =>
	// 		require([
	// 			"../views/application/enterpriseCockpitForIndustry/enterpriseCockpitForIndustry",
	// 		], resove),
	// },
	// {
	// 	// 智能家电产业数据驾驶舱
	// 	path: "/application/industryDataCockpit",
	// 	name: "industryDataCockpit",
	// 	component: (resove) =>
	// 		require([
	// 			"../views/application/industryDataCockpit/industryDataCockpit",
	// 		], resove),
	// },
	// {
	// 	// 产业链态势分析
	// 	path: "/application/industryChainSituation",
	// 	name: "industryChainSituation",
	// 	component: (resove) =>
	// 		require([
	// 			"../views/application/industryChainSituation/industryChainSituation",
	// 		], resove),
	// },
	{
		path: "/",
		name: "indexFrame",
		component: (resove) => require(["../views/indexFrame"], resove),
		redirect: "index",
		children: [
			{
				// 首页
				path: "/index",
				name: "index",
				component: (resove) => require(["../views/index/index.vue"], resove),
			},
			{
				// 新闻动态
				path: "/news",
				name: "news",
				component: (resove) => require(["../views/news/index.vue"], resove),
			},
			{
				// 新闻动态详情
				path: "/news/newsDetail/:ids",
				name: "newsDetail",
				component: (resove) =>
					require(["../views/news/newsDetail.vue"], resove),
			},
			// {
			// 	// 解决方案
			// 	path: "/solution",
			// 	name: "solution",
			// 	component: (resove) => require(["../views/solution/index.vue"], resove),
			// },
			{
				//解决方案商城
				path: "/solution/solutionStore",
				name: "solutionStore",
				component: (resove) =>
					require(["../views/solution/solutionStore.vue"], resove),
			},
			{
				//解决方案详情页
				path: "/solution/solutionStore/solutionDetails",
				name: "solutionDetails",
				component: (resove) =>
					require(["../views/solution/solutionDetails.vue"], resove),
			},
			// {
			// 	//供需中心
			// 	path: "/demand",
			// 	name: "demand",
			// 	component: (resove) => require(["../views/demand/demand.vue"], resove),
			// },
			// 智能问答
			{
				path: '/answer-question',
				name: 'answerQuestion',
				redirect: '/answer-question/home',
				component: (resove) => require(['../views/answerQuestion/index.vue'], resove),
				children: [
					{
						path: '/answer-question/home',
						name: 'AnswerHome',
						component: (resove) => require(['../views/answerQuestion/components/rightContent.vue'], resove)
					},
					{
						path: '/answer-question/answer',
						name: 'AnswerContent',
						component: (resove) => require(['../views/answerQuestion/components/rightAnswer.vue'], resove)
					}
				]
			},
			{
				//供需中心详情页
				path: "/demandDetail",
				name: "demandDetail",
				component: (resove) =>
					require(["../views/demand/demandDetail.vue"], resove),
			},
			{
				// 场景应用
				path: "/application",
				name: "application",
				component: (resove) =>
					require(["../views/application/index.vue"], resove),
			},
			{
				// 典型案例
				path: "/case/:id",
				name: "case",
				component: (resove) => require(["../views/case/index.vue"], resove),
			},
			{
				// 典型案例详情
				path: "/case/info/:id",
				name: "case",
				component: (resove) => require(["../views/case/info.vue"], resove),
			},
			{
				// 企业信息
				path: "/enterpriseInformation",
				name: "enterpriseInformation",
				component: (resove) =>
					require([
						"../views/userControl/enterpriseUser/enterpriseInformation/enterpriseInformation.vue",
					], resove),
			},
			{
				// 企业人员管理
				path: "/staffManagement",
				name: "staffManagement",
				component: (resove) =>
					require([
						"../views/userControl/enterpriseUser/staffManagement.vue",
					], resove),
			},
			{
				// 企业角色管理
				path: "/characterManagement",
				name: "characterManagement",
				component: (resove) =>
					require([
						"../views/userControl/enterpriseUser/characterManagement.vue",
					], resove),
			},
			{
				// 企业需求管理
				path: "/demandManagement",
				name: "demandManagement",
				component: (resove) =>
					require([
						"../views/userControl/enterpriseUser/demandManagement.vue",
					], resove),
			},
			{
				// 密码管理
				path: "/passwordManagement",
				name: "passwordManagement",
				component: (resove) =>
					require(["../views/userControl/passwordManagement.vue"], resove),
			},
			{
				// 个人信息
				path: "/individualInformation",
				name: "individualInformation",
				component: (resove) =>
					require([
						"../views/userControl/individualUser/individualInformation.vue",
					], resove),
			},
			{
				//产业服务
				path: "/industrialService",
				name: "industrialService",
				component: () => import("../views/industrialService/index.vue"),
			},
			// 产业服务(新)
			{
				//产业服务
				path: "/industrialServiceNew",
				name: "industrialServiceNew",
				component: () => import("../views/industrialServiceNew/index.vue"),
			},
			// {
			// 	//设备交易平台
			// 	path: "/industrialService/tradingPlatform",
			// 	name: "productDetails",
			// 	component: () =>
			// 		import("../views/industrialService/tradingPlatform.vue"),
			// },
			{
				//摩根设计
				path: "/industrialService/morganDesign",
				name: "morganDesign",
				component: () => import("../views/industrialService/morganDesign.vue"),
			},
			// {
			// 	// 产业链金融
			// 	path: "/industrialService/industryChainFinance",
			// 	name: "industryChainFinance",
			// 	component: () =>
			// 		import("../views/industrialService/industryChainFinance.vue"),
			// },
			// {
			// 	// 家电详情
			// 	path: "/application/allInformation/:ids",
			// 	name: "allInformation",
			// 	component: (resove) =>
			// 		require([
			// 			"../views/application/IndustryTank/component/Atlas",
			// 		], resove),
			// },
			// {
			// 	// 物联网平台
			// 	path: "/application/serviceProvider",
			// 	name: "serviceProvider",
			// 	component: (resove) =>
			// 		require(["../views/application/serviceProvider"], resove),
			// },
			// {
			// 	// 知识图谱
			// 	path: "/application/industryTank",
			// 	name: "industryTank",
			// 	component: (resove) =>
			// 		require(["../views/application/IndustryTank"], resove),
			// },
			// {
			// 	// 企业智诊断
			// 	path: "/application/selfTesting",
			// 	name: "selfTesting",
			// 	component: (resove) =>
			// 		require(["../views/application/selfTesting"], resove),
			// },
			// {
			// 	// 产业链图谱
			// 	path: "/application/industrialChain",
			// 	name: "industrialChain",
			// 	component: (resove) =>
			// 		require(["../views/application/industrialChain"], resove),
			// },
			// {
			// 	// 产业链图谱详情
			// 	path: "/application/chainInformation",
			// 	name: "chainInformation",
			// 	component: (resove) =>
			// 		require(["../views/application/industrialChain/details.vue"], resove),
			// },
			// {
			// 	// 成果转化
			// 	path: "/application/achievementTransformation",
			// 	name: "achievementTransformation",
			// 	component: (resove) =>
			// 		require([
			// 			"../views/application/achievementTransformation/achievementTransformation",
			// 		], resove),
			// },
			// {
			// 	// 专家大厅
			// 	path: "/application/expertHall",
			// 	name: "expertHall",
			// 	component: (resove) =>
			// 		require(["../views/application/expertHall/expertHall"], resove),
			// },
			{
				// 企业驾驶舱
				path: "/application/enterpriseCockpit",
				name: "enterpriseCockpit",
				component: (resove) =>
					require([
						"../views/application/enterpriseCockpit/enterpriseCockpit",
					], resove),
			},
			// {
			// 	// 产品画像
			// 	path: "/application/productPortrait",
			// 	name: "productPortrait",
			// 	component: (resove) =>
			// 		require([
			// 			"../views/application/productPortrait/productPortrait.vue",
			// 		], resove),
			// },
			// {
			// 	// 知识培训
			// 	path: "/application/knowledgeTraining",
			// 	name: "knowledgeTraining",
			// 	component: (resove) =>
			// 		require([
			// 			"../views/application/knowledgeTraining/knowledgeTraining.vue",
			// 		], resove),
			// },
			// {
			// 	// 企业风险预警
			// 	path: "/application/riskWarning",
			// 	name: "riskWarning",
			// 	component: (resove) =>
			// 		require(["../views/application/riskWarning/index.vue"], resove),
			// },
			// {
			// 	// 产业云图
			// 	path: "/application/industrialMap",
			// 	name: "industrialMap",
			// 	component: (resove) =>
			// 		require([
			// 			"../views/application/IndustrialMap/IndustrialMap.vue",
			// 		], resove),
			// },
			// {
			// 	// 行业标准库
			// 	path: "/application/IndustryStandard",
			// 	name: "IndustryStandard",
			// 	component: (resove) =>
			// 		require([
			// 			"../views/application/IndustryStandard/IndustryStandard.vue",
			// 		], resove),
			// },
			// {
			// 	// 产品评测
			// 	path: "/application/productTests",
			// 	name: "productTests",
			// 	component: (resove) =>
			// 		require(["../views/application/productTests/index.vue"], resove),
			// },
			// {
			// 	// 家电产品转移监测
			// 	path: "/application/transferMonitoring",
			// 	name: "transferMonitoring",
			// 	component: (resove) =>
			// 		require([
			// 			"../views/application/transferMonitoring/transferMonitoring.vue",
			// 		], resove),
			// },
			// {
			// 	// 全网比价
			// 	path: "/application/priceComparison",
			// 	name: "priceComparison",
			// 	component: (resove) =>
			// 		require([
			// 			"../views/application/priceComparison/priceComparison.vue",
			// 		], resove),
			// },
			// {
			// 	// 全网比价-收藏页
			// 	path: "/application/priceComparison/star",
			// 	name: "priceComparisonStar",
			// 	component: (resove) =>
			// 		require(["../views/application/priceComparison/star.vue"], resove),
			// },
			// {
			// 	// 全网比价-商品详情页
			// 	path: "/application/priceComparison/detail/:id",
			// 	name: "priceComparisonDetail",
			// 	component: (resove) =>
			// 		require(["../views/application/priceComparison/detail.vue"], resove),
			// },
			// {
			// 	// 全网比价-商品对比页
			// 	path: "/application/priceComparison/compare",
			// 	name: "priceCompare",
			// 	component: (resove) =>
			// 		require(["../views/application/priceComparison/compare.vue"], resove),
			// },
			// {
			// 	// 产业咨询报告-首页
			// 	path: "/application/industryConsultancyReport",
			// 	name: "industryConsultancyReport",
			// 	component: (resove) =>
			// 		require([
			// 			"../views/application/IndustryConsultancyReport/IndustryConsultancyReport",
			// 		], resove),
			// },
			{
				// 产业咨询报告-收藏页
				path: "/application/industryConsultancyReport/star",
				name: "industryConsultancyStarReport",
				component: (resove) =>
					require([
						"../views/application/IndustryConsultancyReport/star",
					], resove),
			},
			// {
			// 	// 地区指数分析
			// 	path: "/application/regional",
			// 	name: "regional",
			// 	component: (resove) =>
			// 		require(["../views/application/regional/regional.vue"], resove),
			// },
			// {
			// 	// 模型市场-首页
			// 	path: "/application/modelMarket",
			// 	name: "modelMarket",
			// 	component: (resove) =>
			// 		require(["../views/application/modelMarket/modelMarket.vue"], resove),
			// },
			// {
			// 	// 模型市场-收藏页
			// 	path: "/application/modelMarket/star",
			// 	name: "modelMarketStar",
			// 	component: (resove) =>
			// 		require(["../views/application/modelMarket/star"], resove),
			// },
			// {
			// 	// 模型市场-我的模型页
			// 	path: "/application/modelMarket/myModel",
			// 	name: "myModelMarket",
			// 	component: (resove) =>
			// 		require(["../views/application/modelMarket/myModel"], resove),
			// },
			{
				// 模型市场-模型详情页
				path: "/application/modelMarket/detail/:id",
				name: "modelDetail",
				component: (resove) =>
					require(["../views/application/modelMarket/detail"], resove),
			},
			// {
			// 	// 惠企政策
			// 	path: "/application/favorableEnterprisePolicy",
			// 	name: "favorableEnterprisePolicy",
			// 	component: (resove) =>
			// 		require(["../views/application/favorableEnterprisePolicy"], resove),
			// },
			{
				// 惠企政策详情
				path: "/application/policyInformation",
				name: "policyInformation",
				component: (resove) =>
					require([
						"../views/application/favorableEnterprisePolicy/details.vue",
					], resove),
			},
			// {
			// 	// 物流科技详情
			// 	path: "/application/logistics",
			// 	name: "logistics",
			// 	component: (resove) =>
			// 		require(["../views/application/logistics/index.vue"], resove),
			// },
			// {
			// 	// 产业用工分析
			// 	path: "/application/industrialEmploymentAnalysis",
			// 	name: "industrialEmploymentAnalysis",
			// 	component: (resove) =>
			// 		require([
			// 			"../views/application/IndustrialEmploymentAnalysis/IndustrialEmploymentAnalysis",
			// 		], resove),
			// },
			{
				// 产品榜单
				path: "/application/productList",
				name: "productList",
				component: (resove) =>
					require(["../views/application/productList"], resove),
			},
			{
				// 产业链态势分析
				path: "/overview",
				name: "Overview",
				component: (resove) =>
					require([
						"../views/application/industryChainSituation/industryChainSituation",
					], resove),
			},
			// 智能搜索
			{
				path: '/intellect-search',
				name: 'IntellectSearch',
				component: (resove) => require(['../views/index/component/intellect-search.vue'], resove)
			},
			{
				// 工作台
				path: '/workbench',
				name: 'Workbench',
				component: (resove) => require(['../views/workbench/index.vue'], resove),
				redirect: '/workbench/home',
				children: [
					{
						path: '/workbench/home',
						name: 'WHome',
						component: (resove) => require(['../views/workbench/components/home.vue'], resove)
					},
					{
						path: '/workbench/service-manage',
						name: 'WService',
						component: (resove) => require(['../views/workbench/components/service-manage.vue'], resove)
					}
				]
			},
			// 服务详情
			{
				path: '/service/detail',
				name: 'ServiceDetail',
				component: (resove) => require(["../views/industrialServiceNew/components/serviceDetail.vue"], resove)
			}
		],
	},
	// ==================跳转新页面======================
	{
		// 知识图谱
		path: "/application/industryTank",
		name: "industryTank",
		component: (resove) =>
			require(["../views/application/IndustryTank"], resove),
	},
	{
		// 产业链图谱
		path: "/application/industrialChain",
		name: "industrialChain",
		component: (resove) =>
			require(["../views/application/industrialChain"], resove),
	},
	{
		// 惠企政策
		path: "/application/favorableEnterprisePolicy",
		name: "favorableEnterprisePolicy",
		component: (resove) =>
			require(["../views/application/favorableEnterprisePolicy"], resove),
	},
	{
		// 产品画像
		path: "/application/productPortrait",
		name: "productPortrait",
		component: (resove) =>
			require([
				"../views/application/productPortrait/productPortrait.vue",
			], resove),
	},
	{
		// 成果转化
		path: "/application/achievementTransformation",
		name: "achievementTransformation",
		component: (resove) =>
			require([
				"../views/application/achievementTransformation/achievementTransformation",
			], resove),
	},
	{
		// 产品评测
		path: "/application/productTests",
		name: "productTests",
		component: (resove) =>
			require(["../views/application/productTests/index.vue"], resove),
	},
	{
		// 全网比价
		path: "/application/priceComparison",
		name: "priceComparison",
		component: (resove) =>
			require([
				"../views/application/priceComparison/priceComparison.vue",
			], resove),
	},
	{
		// 全网比价-收藏页
		path: "/application/priceComparison/star",
		name: "priceComparisonStar",
		component: (resove) =>
			require(["../views/application/priceComparison/star.vue"], resove),
	},
	{
		// 全网比价-商品详情页
		path: "/application/priceComparison/detail/:id",
		name: "priceComparisonDetail",
		component: (resove) =>
			require(["../views/application/priceComparison/detail.vue"], resove),
	},
	{
		// 全网比价-商品对比页
		path: "/application/priceComparison/compare",
		name: "priceCompare",
		component: (resove) =>
			require(["../views/application/priceComparison/compare.vue"], resove),
	},
	{
		// 行业标准库
		path: "/application/IndustryStandard",
		name: "IndustryStandard",
		component: (resove) =>
			require([
				"../views/application/IndustryStandard/IndustryStandard.vue",
			], resove),
	},
	{
		// 地区指数分析
		path: "/application/regional",
		name: "regional",
		component: (resove) =>
			require(["../views/application/regional/regional.vue"], resove),
	},
	{
		// 家电详情
		path: "/application/allInformation/:ids",
		name: "allInformation",
		component: (resove) =>
			require([
				"../views/application/IndustryTank/component/Atlas",
			], resove),
	},
	{
		// 产业链图谱详情
		path: "/application/chainInformation",
		name: "chainInformation",
		component: (resove) =>
			require(["../views/application/industrialChain/details.vue"], resove),
	},
	{
		// 产业咨询报告-首页
		path: "/application/industryConsultancyReport",
		name: "industryConsultancyReport",
		component: (resove) =>
			require([
				"../views/application/IndustryConsultancyReport/IndustryConsultancyReport",
			], resove),
	},
	{
		// 产业用工分析
		path: "/application/industrialEmploymentAnalysis",
		name: "industrialEmploymentAnalysis",
		component: (resove) =>
			require([
				"../views/application/IndustrialEmploymentAnalysis/IndustrialEmploymentAnalysis",
			], resove),
	},
	{
		// 家电产品转移监测
		path: "/application/transferMonitoring",
		name: "transferMonitoring",
		component: (resove) =>
			require([
				"../views/application/transferMonitoring/transferMonitoring.vue",
			], resove),
	},
	{
		// 产业驾驶舱
		path: "/application/enterpriseCockpitForIndustry",
		name: "enterpriseCockpitForIndustry",
		component: (resove) =>
			require([
				"../views/application/enterpriseCockpitForIndustry/enterpriseCockpitForIndustry",
			], resove),
	},
	{
		// 物流科技详情
		path: "/application/logistics",
		name: "logistics",
		component: (resove) =>
			require(["../views/application/logistics/index.vue"], resove),
	},
	// {
	// 	// 产业链态势分析
	// 	path: "/overview",
	// 	name: "Overview",
	// 	component: (resove) =>
	// 		require([
	// 			"../views/application/industryChainSituation/industryChainSituation",
	// 		], resove),
	// },
	{
		// 产业链金融
		path: "/industrialService/industryChainFinance",
		name: "industryChainFinance",
		component: () =>
			import("../views/industrialService/industryChainFinance.vue"),
	},
	{
		// 企业风险预警
		path: "/application/riskWarning",
		name: "riskWarning",
		component: (resove) =>
			require(["../views/application/riskWarning/index.vue"], resove),
	},
	{
		// 模型市场-首页
		path: "/application/modelMarket",
		name: "modelMarket",
		component: (resove) =>
			require(["../views/application/modelMarket/modelMarket.vue"], resove),
	},
	{
		// 智能家电产业数据驾驶舱
		path: "/application/industryDataCockpit",
		name: "industryDataCockpit",
		component: (resove) =>
			require([
				"../views/application/industryDataCockpit/industryDataCockpit",
			], resove),
	},
	{
		// 企业智诊断
		path: "/application/selfTesting",
		name: "selfTesting",
		component: (resove) =>
			require(["../views/application/selfTesting"], resove),
	},
	{
		// 知识培训
		path: "/application/knowledgeTraining",
		name: "knowledgeTraining",
		component: (resove) =>
			require([
				"../views/application/knowledgeTraining/knowledgeTraining.vue",
			], resove),
	},
	{
		// 专家大厅
		path: "/application/expertHall",
		name: "expertHall",
		component: (resove) =>
			require(["../views/application/expertHall/expertHall"], resove),
	},
	{
		// 物联网平台
		path: "/application/serviceProvider",
		name: "serviceProvider",
		component: (resove) =>
			require(["../views/application/serviceProvider"], resove),
	},
	{
		// 产业云图
		path: "/application/industrialMap",
		name: "industrialMap",
		component: (resove) =>
			require([
				"../views/application/IndustrialMap/IndustrialMap.vue",
			], resove),
	},
	{
		//设备交易平台
		path: "/industrialService/tradingPlatform",
		name: "productDetails",
		component: () =>
			import("../views/industrialService/tradingPlatform.vue"),
	},
	{
		//供需中心
		path: "/demand",
		name: "demand",
		component: (resove) => require(["../views/demand/demand.vue"], resove),
	},
	{
		// 解决方案
		path: "/solution",
		name: "solution",
		component: (resove) => require(["../views/solution/index.vue"], resove),
	},
	{
		// 模型市场-收藏页
		path: "/application/modelMarket/star",
		name: "modelMarketStar",
		component: (resove) =>
			require(["../views/application/modelMarket/star"], resove),
	},
	{
		// 模型市场-我的模型页
		path: "/application/modelMarket/myModel",
		name: "myModelMarket",
		component: (resove) =>
			require(["../views/application/modelMarket/myModel"], resove),
	},
];

const router = new VueRouter({
	// mode: "history",
	mode: "hash",
	base: process.env.BASE_URL,
	routes,
});

export default router;
