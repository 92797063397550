<template>
  <div id="app">
    <router-view />
    <el-dialog
      title="登录"
      :visible.sync="loginDialog"
      width="25%"
      :close-on-click-modal="false"
      :before-close="handleClose">
      <div id="login">
        <div class="from">
          <el-radio-group v-model="tabPosition" class="tab">
            <el-radio-button label="法人登录">法人登录</el-radio-button>
            <el-radio-button label="个人登录">个人登录</el-radio-button>
          </el-radio-group>
          <!-- 法人登录 -->
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm" v-if="tabPosition == '法人登录'">
            <el-form-item prop="username">
              <el-input type="username" placeholder="请输入用户名称" v-model="ruleForm.username" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input type="password" placeholder="请输入密码" v-model="ruleForm.password" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item prop="code">
              <div class="code">
                <el-input type="code" placeholder="请输入验证码" v-model="ruleForm.code" autocomplete="off"></el-input>
                <el-image height="40px" :src="imgsrc['image']" @click="getimage" @error="handleError"></el-image>
              </div>
            </el-form-item>
            <el-form-item>
              <el-button class="loginButton" type="primary" @click="submitForm('ruleForm')">登录</el-button>
              <div class="no-sign">
                <p>没有账号?</p>
                <a @click="goRegister('法人注册')">
                  <p>立即注册</p>
                </a>
                <a @click="goPortal" style="position: absolute; right: 0">
                  <p>产业大脑能力中心授权登录</p>
                </a>
              </div>
            </el-form-item>
          </el-form>
          <!-- 个人登录 -->
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm" v-if="tabPosition === '个人登录'">
            <el-form-item prop="individualPhone">
              <el-input type="phone" placeholder="请输入手机号码" v-model="ruleForm.individualPhone" autocomplete="off">
              </el-input>
            </el-form-item>
            <el-form-item prop="individualPassword">
              <el-input type="password" placeholder="请输入密码" v-model="ruleForm.individualPassword" autocomplete="off">
              </el-input>
            </el-form-item>
            <el-form-item prop="individualCode">
              <div class="code">
                <el-input type="code" placeholder="请输入验证码" v-model="ruleForm.individualCode" autocomplete="off">
                </el-input>
                <el-image :src="imgsrc['image']" @click="getimage" @error="handleError"></el-image>
              </div>
            </el-form-item>
            <el-form-item>
              <el-button class="loginButton" type="primary" @click="individualSubmitForm('ruleForm')">登录</el-button>
              <div class="no-sign">
                <p>没有账号?</p>
                <a @click="goRegister('个人注册')">
                  <p>立即注册</p>
                </a>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span> -->
    </el-dialog>
  </div>
</template>

<script>
import { accessTracking } from "@/api/buryPoint.js";
import { Pics } from "@/utils/index";
import { _cookie } from "@/utils/token";
import { login, personalLogin, fetchCompanyName,fetchCaptcha } from "@/api/login.js";

export default {
  data() { 
    return {
      loginDialog: false,
      Pics,
      noLogin: "",
      imgsrc: "",
      errTab: false,
      msg: "",
      ruleForm: {
        username: "",
        password: "",
        code: "",
        individualPhone: "",
        individualPassword: "",
        individualCode: "",
      },
      rules: {
        username: [{ required: true, message: "必填", trigger: "blur" }],
        password: [{ required: true, message: "必填", trigger: "blur" }],
        code: [{ required: true, message: "必填", trigger: "blur" }],
        individualPhone: [{ required: true, message: "必填", trigger: "blur" }],
        individualPassword: [
          { required: true, message: "必填", trigger: "blur" },
        ],
        individualCode: [{ required: true, message: "必填", trigger: "blur" }],
      },
      showCompany: false,
      companyList: [],
      tabPosition: "法人登录",
      excludePath: ["#/index", "#/login", "#/news"],
    }
  },
  watch: {
    "$route.path": {
      immediate: true,
      handler(newPath) {
        console.log("99999", newPath);
        this.getAccessTracking(newPath);
        if (this.$route.name === "industryDataCockpit") {
          document.getElementById("app").style.backgroundImage = `url(${require('@/assets/img/application/industryChainSituation/bg.png')})`
        }else{
          document.getElementById("app").style.backgroundImage = "none"
        }
        this.noLogin = _cookie.getCookie("noLogin")
        if (this.noLogin == "true") {
          if (newPath !== "/index" || newPath !== "/login") { 
            this.loginDialog = true;
          } 
        }
      },
    },
  },
  created() { 

  },
  methods: {
    getAccessTracking(path) {
      accessTracking({ path: encodeURIComponent(path) })
        .then(() => {})
        .catch((err) => {
          this.$message.error(err);
        });
    },
    handleClose() { 
      this.loginDialog = false;
    },
    goPortal() {
      window.open("https://www.industrybd.cn/#/login");
    },
    goRegister(type) {
      this.loginDialog = false;
      this.$router.push({
        path: "/register",
        query: {
          type,
        },
      }).catch(err => err);
    },
    submitForm() {
      if (
        this.ruleForm.username &&
        this.ruleForm.password !== "" &&
        this.ruleForm.code !== ""
      ) {
        this.login();
        // 重置标志
      } else {
        this.$message.error("登录失败");
        console.log('1111');
      }
    },
    individualSubmitForm() {
      if (
        this.ruleForm.individualPhone &&
        this.ruleForm.individualPassword !== "" &&
        this.ruleForm.individualCode !== ""
      ) {
        this.individualLogin();
        // 重置标志位
      } else {
        this.$message.error("登录失败");
      }
    },
    async login() {
      try {
        let res = await login({
          code: this.ruleForm.code,
          password: this.ruleForm.password,
          username: this.ruleForm.username,
          uuid: this.imgsrc.uuid,
        })
        if (res.code === 0) {
          _cookie.setCookie("token", res.data.token);
          _cookie.setCookie("user", JSON.stringify(res.data.user));
          _cookie.setCookie("role", res.data.role);
          // this.$router.push({ path: "/" }).catch(err => err);
          _cookie.setCookie("noLogin", "false");
          this.loginDialog = false;
          this.$router.go(0)
        } else {
          this.$message.error(res.msg);
        }
      } catch (error) {
        this.$message.error(res.msg);
      }
    },
    async individualLogin() {
      try {
        let res = await personalLogin({
          code: this.ruleForm.individualCode,
          password: this.ruleForm.individualPassword,
          username: this.ruleForm.individualPhone,
          uuid: this.imgsrc.uuid,
        })
        if (res.code === 0) {
          _cookie.setCookie("token", res.data.token);
          _cookie.setCookie("user", JSON.stringify(res.data.user));
          _cookie.setCookie("role", res.data.role);
          _cookie.setCookie("noLogin", "false");
          this.loginDialog = false;
          this.$router.go(0)
          // this.$router.push({ path: "/" }).catch(err => err);
          // document
          //   .querySelector("body")
          //   .setAttribute("style", "background:none;");
        } else {
          this.$message.error(res.msg);
        }
      } catch (error) {
        this.$message.error(error);
      }
    },
    async getNews(k) {
      try {
        let res = await fetchCompanyName({ k })
        if (res.code === 0) {
          this.showCompany = true;
          this.companyList = res.data;
        } else {
          this.$message.error(res.msg)
        }
      } catch (error) {
        this.$message.error(error)
      }
    },
    async getimage() {
      try {
        let res = await fetchCaptcha()
        if (res.code === 0) {
          this.imgsrc = res.data;
        } else {
          this.$message.error(res.msg)
        }
      } catch (error) {
        this.$message.error(error)
      }

    },
    handleError() {
      this.getimage()
    }
  },
  mounted() {
    const token = _cookie.getCookie("token");
    console.log("0-0-0-0", window.location.hash.split("?")[0]);
    if (token == null) {
      if (!this.excludePath.includes(window.location.hash.split("?")[0])) { 
        _cookie.setCookie("noLogin", "true");
      }
      if (this.excludePath.includes(window.location.hash.split("?")[0])) { 
        this.loginDialog = false;
        _cookie.clearAllCookies();
      }
    }
    this.getimage();
    this.tabPosition = this.$route.query.type
      ? this.$route.query.type
      : "法人登录";
  }
};
</script>

<style lang="less" scoped>
#login {
  .from {
    border-radius: 10px;
    // width: 420px;
    background-color: #fff;
    // position: absolute;
    // top: 30%;
    // left: 60%;
    box-sizing: border-box;
    padding: 24px;

    .tab {
      margin-left: calc(50% - 100px);
      margin-bottom: 24px;

      label {
        width: 100px;
      }
    }

    .from-p {
      font-size: 18px;
      font-weight: bold;
      width: 72px;
      margin: 35px auto;
    }

    .err {
      color: red;
      margin-left: 30px;
    }

    .loginButton {
      width: 100%;
    }

    .no-sign {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;

      p {
        color: #c4c4c4;
      }

      a p {
        color: #6495ed;
        cursor: pointer;
      }
    }
  }

  .demo-ruleForm {
    padding: 24px;
    height: 240px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    .el-form-item {
      position: relative;
      width: 100%;

      .code {
        display: flex;
        flex-direction: row;

        // .el-image__inner{
        //   hei
        // }
        .el-image {
          width: 300px;
          height: 40px;
        }
      }

      .modal {
        position: absolute;
        width: calc(100% - 32px);
        max-height: 200px;
        background-color: white;
        border: 1px solid #dcdfe6;
        z-index: 999;
        padding: 0 15px;
        overflow: auto;
      }
    }
  }
}
</style>