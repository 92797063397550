function calcStrLen(str) {
    var len = 0;
    for (var i = 0; i < str.length; i++) {
      if (str.charCodeAt(i) > 0 && str.charCodeAt(i) < 128) {
        len++;
      } else {
        len += 2;
      }
    }
    return len;
  }
  export function fittingString(str, maxWidth, fontSize) {
    // var fontWidth = fontSize * 1.3;
    // maxWidth = maxWidth * 2;
    var fontWidth = fontSize*1;
    maxWidth = maxWidth;
    var width = calcStrLen(str) * fontWidth;
    var ellipsis = "…";
    if (width > maxWidth) {
      var actualLen = Math.floor((maxWidth - 10) / fontWidth);
      if(actualLen<str.length){
        var result = str.substring(0, actualLen) + ellipsis
      }else{
        var result = str.substring(0, actualLen);
      }
      return result;
    }
    return str;
};