import axios from "../plugin/axios";

/**
 * 个人用户登录
 * @method POST
 */
export async function personalLogin(params) {
  const response = await axios.post(`/home/person/login`, params);
  return response.data;
}

/**
 * 企业用户登录
 * @method POST
 */
 export async function login(data) {
  const response = await axios.post(`/home/login`,data)
  return response.data
}

/**
 * 企业用户注册
 * @method POST
 */
 export async function register(data) {
  const response = await axios.post(`/home/register`,data)
  return response.data
}

/**
 * 个人用户注册
 * @method POST
 */
 export async function personalRegister(data) {
  const response = await axios.post(`/home/person/register`,data)
  return response.data
}

/**
 * 发送短信(注册)
 * @method POST
 */
 export async function sendSmsToRegister(phone,type) {
  const response = await axios.post(`/common/sms/send/register?phone=${phone}&type=${type}`)
  return response.data
}

// 用code获取用户和企业信息
export async function loginInfo(code) {
  const response = await axios.get(`/login/user/${code}/info`);
  return response.data;
}

/**
 * 获取用户状态
 * @method GET
 */
export async function fetchPersonStatus(params) {
  const response = await axios.get(`/home/person/status`, { params })
  return response.data
}

/**
 * 注销登录
 * @method POST
 */
export async function logout(data) {
  const response = await axios.post(`/home/logout`, data)
  return response.data
}

/**
 * SSO登录
 * @method POST
 */
export async function ssoLogin(code) {
  const response = await axios.post(`/sso/login?code=${code}`)
  return response.data
}

/**
 * 绑定企业
 * @method POST
 */
export async function bindEnterprise(data) {
  const response = await axios.post(`/home/person/binding`, data)
  return response.data
}

/**
 * 企业名称列表 10条
 * @method GET
 */
export async function fetchCompanyName(params) {
  const response = await axios.get(`/home/clientCompany/getCompanyName`, { params })
  return response.data
}

/**
 * 角色列表
 * @method POST
 */
export async function fetchRoleList(data) {
  const response = await axios.post(`/enterprise/manger/role/list`, data)
  return response.data
}

/**
 * 企业人员绑定审核列表
 * @method POST
 */
export async function fetchManagerList(data) {
  const response = await axios.post(`/enterprise/manger/list`, data)
  return response.data
}

/**
 * 企业人员绑定
 * @method POST
 */
export async function bindEnterpriseManager(data) {
  const response = await axios.post(`/enterprise/manger/bind`, data)
  return response.data
}

/**
 * 企业人员分配角色
 * @method POST
 */
export async function assignMangerRole(data) {
  const response = await axios.post(`/enterprise/manger/AssigningRole`, data)
  return response.data
}

/**
 * 启用/禁用角色
 * @method POST
 */
export async function updateRoleStatus(id, action) {
  const response = await axios.post(`/enterprise/manger/role/update?id=${id}&action=${action}`)
  return response.data
}

/**
 * 添加角色
 * @method PUT
 */
export async function addRole(data) {
  const response = await axios.put(`/enterprise/manger/role/add`, data)
  return response.data
}

/**
 * 删除角色
 * @method DELETE
 */
export async function deleteRole(params) {
  const response = await axios.delete(`/enterprise/manger/role/delete`, { params })
  return response.data
}

/**
 * 发送短信(修改密码)
 * @method POST
 */
export async function fixPassword(phone, type) {
  const response = await axios.post(`/common/sms/send/changePwd?phone=${phone}&type=${type}`)
  return response.data
}

/**
 * 企业修改密码
 * @method POST
 */
export async function fixEnterprisePassword(data) {
  const response = await axios.post(`/client/info/changePsw`, data)
  return response.data
}

/**
 * 注册时上传文件
 * @method POST
 */
 export async function uploadFileForRegister(data) {
  const response = await axios.post(`/common/file/upload`, data)
  return response.data
}

/**
 * 获取图片验证码
 * @method GET
 */
 export async function fetchCaptcha(params) {
  const response = await axios.get(`/home/captcha`, { params })
  return response.data
}

/**
 * 修改当前用户密码
 * @method POST
 */
 export async function updatePersonalPwd(data) {
  const response = await axios.post(`/updatePwd`, data)
  return response.data
}

/**
 * 获取code和机构id
 * @method POST
 */
export async function getCode() {
  const response = await axios.post(`/vip/encrypt`)
  return response.data
}


