export const Pics = {
  login: require("../assets/img/login.jpeg"),
  logo: require("../assets/img/logo.png"),
  logoNew: require("../assets/img/favicon.png"),
  banner1: require("../assets/img/b753abfa512452e8f0e24a7856454abe.jpeg"),
  banner2: require("../assets/img/1b6e6ed5345caf8ec21428998371c8cc.jpeg"),
  banner3: require("../assets/img/958bc256f63323298d379f1b24c92be9.jpeg"),
  banner4: require("../assets/img/swipper-bg.png"),
  banner5: require("../assets/search/home-banner.gif"),
  banner6: require("../assets/search/banner.mp4"),
  tupu: require("../assets/img/tupu.png"),
  yuntu: require("../assets/img/yuntu.png"),
  chanyelian: require("../assets/img/chanyelian.png"),
  zhuanjiaku: require("../assets/img/zhuanjiaku.png"),
  huaxiang: require("../assets/img/huaxiang.png"),
  biaozhunku: require("../assets/img/biaozhunku.png"),
  chengguo: require("../assets/img/chengguo.png"),
  wulianwang: require("../assets/img/wulianwang.png"),
  yujing: require("../assets/img/yujing.png"),
  tupu_c: require("../assets/img/tupu_c.png"),
  yuntu_c: require("../assets/img/yuntu_c.png"),
  chanyelian_c: require("../assets/img/chanyelian_c.png"),
  zhuanjiaku_c: require("../assets/img/zhuanjiaku_c.png"),
  huaxiang_c: require("../assets/img/huaxiang_c.png"),
  biaozhunku_c: require("../assets/img/biaozhunku_c.png"),
  chengguo_c: require("../assets/img/chengguo_c.png"),
  wulianwang_c: require("../assets/img/wulianwang_c.png"),
  yujing_c: require("../assets/img/yujing_c.png"),
  zhuanli_c: require("../assets/search/zhuanli-normal.png"),
  zhuanli: require("../assets/search/zhuanli-hover.png"),

  ceping: require("../assets/img/ceping.png"),
  ceping_c: require("../assets/img/ceping_c.png"),

  sheji: require("../assets/img/sheji.png"),
  shengchan: require("../assets/img/shengchan.png"),
  yunwei: require("../assets/img/yunwei.png"),
  nenghao: require("../assets/img/nenghao.png"),
  anquan: require("../assets/img/anquan.png"),
  zhiliang: require("../assets/img/zhiliang.png"),
  sheji_a: require("../assets/img/sheji_a.png"),
  shengchan_a: require("../assets/img/shengchan_a.png"),
  yunwei_a: require("../assets/img/yunwei_a.png"),
  nenghao_a: require("../assets/img/nenghao_a.png"),
  anquan_a: require("../assets/img/anquan_a.png"),
  zhiliang_a: require("../assets/img/zhiliang_a.png"),
  solutionlogo: require("../assets/img/solutionlogo.png"),
  logo_huawei: require("../assets/img/huawei.png"),
  s_yunze: require("../assets/img/yunze.png"),
  s_keqiang: require("../assets/img/keqiang.png"),
  s_zhiyin: require("../assets/img/zhiyin.png"),
  s_xieke: require("../assets/img/xieke.png"),
  s_yunsu: require("../assets/img/yunsu.png"),
  s_jiechuang: require("../assets/img/jiechuang.png"),
  s_changda: require("../assets/img/changda.png"),
  s_lvmeng: require("../assets/img/lvmeng.png"),
  s_baixun: require("../assets/img/baixun.png"),
  s_boyan: require("../assets/img/boyan.png"),

  c_huawei: require("../assets/img/c-huawei.png"),
  c_wotu: require("../assets/img/c-wotu.png"),
  c_ita: require("../assets/img/c-ita.png"),
  case: require("../assets/img/case.png"),
  nocase1: require("../assets/img/case/nocase1.png"),

  news: require("../assets/img/news.png"),
  check: require("../assets/img/check.png"),

  code: require("../assets/img/new-code.jpg"),
  // code: require("../assets/img/code.png"),
  call: require("../assets/img/call.png"),
  bottom: require("../assets/img/bottom.png"),

  c_mensuo: require("../assets/img/case/fb4d6f0396a8ef9e38e1b92c82e35ae1.png"),
  c_yanzao: require("../assets/img/case/a23a65fe32a3482dae5dd3363d40276d.png"),
  c_jingshui: require("../assets/img/case/3bd3f4448461ddabdd3b42a140c2a0fc.png"),
  c_zhibingji: require("../assets/img/case/973955411ac36814064f20fa4763edc4.png"),
  c_xiwanji: require("../assets/img/case/eafe0037c2feea4c45fe144460a9f0dd.png"),
  c_yashua: require("../assets/img/case/0a99dee1d1b450a3064f6ef93533e524.png"),
  c_chuanglian: require("../assets/img/case/2eabb658c20909bb7b80df77e5e3fbf9.png"),
  c_jinghuaqi: require("../assets/img/case/5cbe8ed4e2cff307094124995be513a5.png"),

  ch_zhinenghua: require("../assets/img/case/zhinenghua.jpg"),
  ch_gongyinglian: require("../assets/img/case/gongyinglian.jpg"),
  ch_chanxiao: require("../assets/img/case/chanxiao.jpg"),
  ch_saas: require("../assets/img/case/saas.jpg"),
  ch_erp: require("../assets/img/case/erp.jpg"),
  ch_gongxing: require("../assets/img/case/gongxing.jpg"),

  logistics_1: require("../assets/img/application/logistics/logistics-1.jpg"),
  logistics_2: require("../assets/img/application/logistics/logistics-2.jpg"),
  logistics_3: require("../assets/img/application/logistics/logistics-3.jpg"),
  logistics_4: require("../assets/img/application/logistics/logistics-4.jpg"),
  logistics_5: require("../assets/img/application/logistics/logistics-5.jpg"),
  logistics_6: require("../assets/img/application/logistics/logistics-6.jpg"),
  logistics_7: require("../assets/img/application/logistics/logistics-7.jpg"),
  logistics_8: require("../assets/img/application/logistics/logistics-8.jpg"),
  logistics_9: require("../assets/img/application/logistics/logistics-9.jpg"),

  // 摩根设计
  morganDesign_2: require("../assets/img/industrialService/morganDesign/摩根设计_电脑端_20220520-2.jpg"),
  morganDesign_3: require("../assets/img/industrialService/morganDesign/摩根设计_电脑端_20220520-3.jpg"),
  morganDesign_4: require("../assets/img/industrialService/morganDesign/摩根设计_电脑端_20220520-4.jpg"),
  morganDesign_5: require("../assets/img/industrialService/morganDesign/摩根设计_电脑端_20220520-5.jpg"),
  morganDesign_10: require("../assets/img/industrialService/morganDesign/摩根设计_电脑端_20220520-10.jpg"),
  morganDesign_11: require("../assets/img/industrialService/morganDesign/摩根设计_电脑端_20220520-11.jpg"),
  morganDesign_12: require("../assets/img/industrialService/morganDesign/摩根设计_电脑端_20220520-12.jpg"),
  morganDesign_13: require("../assets/img/industrialService/morganDesign/摩根设计_电脑端_20220520-13.jpg"),
  morganDesign_14: require("../assets/img/industrialService/morganDesign/摩根设计_电脑端_20220520-14.jpg"),
  morganDesign_15: require("../assets/img/industrialService/morganDesign/摩根设计_电脑端_20220520-15.jpg"),
  morganDesign_16: require("../assets/img/industrialService/morganDesign/摩根设计_电脑端_20220520-16.jpg"),
  morganDesign_17: require("../assets/img/industrialService/morganDesign/摩根设计_电脑端_20220520-17.jpg"),
  morganDesign_18: require("../assets/img/industrialService/morganDesign/摩根设计_电脑端_20220520-18.jpg"),
  morganDesign_19: require("../assets/img/industrialService/morganDesign/摩根设计_电脑端_20220520-19.jpg"),
  morganDesign_20: require("../assets/img/industrialService/morganDesign/摩根设计_电脑端_20220520-20.jpg"),
  morganDesign_21: require("../assets/img/industrialService/morganDesign/摩根设计_电脑端_20220520-21.jpg"),
  morganDesign_22: require("../assets/img/industrialService/morganDesign/摩根设计_电脑端_20220520-22.jpg"),
  morganDesign_23: require("../assets/img/industrialService/morganDesign/摩根设计_电脑端_20220520-23.jpg"),
  morganDesign_24: require("../assets/img/industrialService/morganDesign/摩根设计_电脑端_20220520-24.jpg"),
  morganDesign_25: require("../assets/img/industrialService/morganDesign/摩根设计_电脑端_20220520-25.jpg"),
  morganDesign_35: require("../assets/img/industrialService/morganDesign/摩根设计_电脑端_20220520-35.jpeg"),
  morganDesign_contact: require("../assets/img/industrialService/morganDesign/contact.jpeg"),

  // 产业链金融
  industryChainFinance_1: require("../assets/img/industrialService/industryChainFinance/幻灯片1.jpeg"),
  industryChainFinance_2: require("../assets/img/industrialService/industryChainFinance/幻灯片2.jpeg"),
  industryChainFinance_3: require("../assets/img/industrialService/industryChainFinance/幻灯片3.jpeg"),
  industryChainFinance_5: require("../assets/img/industrialService/industryChainFinance/幻灯片5.jpeg"),
  industryChainFinance_6: require("../assets/img/industrialService/industryChainFinance/幻灯片6.jpeg"),
  industryChainFinance_7: require("../assets/img/industrialService/industryChainFinance/幻灯片7.jpeg"),
  industryChainFinance_8: require("../assets/img/industrialService/industryChainFinance/幻灯片8.jpeg"),
  industryChainFinance_15: require("../assets/img/industrialService/industryChainFinance/幻灯片15.jpeg"),
  industryChainFinance_16: require("../assets/img/industrialService/industryChainFinance/幻灯片16.jpeg"),
  industryChainFinance_21: require("../assets/img/industrialService/industryChainFinance/幻灯片21.jpeg"),
  industryChainFinance_22: require("../assets/img/industrialService/industryChainFinance/幻灯片22.jpeg"),
  industryChainFinance_23: require("../assets/img/industrialService/industryChainFinance/幻灯片23.jpeg"),
  industryChainFinance_25: require("../assets/img/industrialService/industryChainFinance/幻灯片25.jpeg"),
  industryChainFinance_26: require("../assets/img/industrialService/industryChainFinance/幻灯片26.jpeg"),
  industryChainFinance_27: require("../assets/img/industrialService/industryChainFinance/幻灯片27.jpeg"),
  industryChainFinance_28: require("../assets/img/industrialService/industryChainFinance/幻灯片28.jpeg"),
  industryChainFinance_29: require("../assets/img/industrialService/industryChainFinance/幻灯片29.jpeg"),
  industryChainFinance_30: require("../assets/img/industrialService/industryChainFinance/幻灯片30.jpeg"),
  industryChainFinance_contact: require("../assets/img/industrialService/industryChainFinance/contact.jpeg"),

  // 优秀服务商
  noDiatance: require("../assets/search/no-distance.jpg"),
  jidongyun: require("../assets/search/jidongyun.jpg"),
  shudongyun: require("../assets/search/shudongyun.jpg"),
};
