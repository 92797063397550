/**
 * 获取一个随机整数(包含 min,max)
 */
//转换日期格式
const dateToString = function(dateS) {
  if (dateS) {
    let date = new Date(dateS);
    let Y = date.getFullYear();
    let M =
      date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1;
    let D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    let H = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    let Min =
      date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    let S =
      date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    return `${Y}-${M}-${D} ${H}:${Min}:${S}`;
  }
  return "";
};
//转换日期格式
const dateFormat = function(dateS) {
  // yyyyMMddTHHmmss
  if (dateS) {
    let date = new Date(dateS);
    let Y = date.getFullYear();
    let M =
      date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1;
    let D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    let H = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    let Min =
      date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    let S =
      date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    return `${Y}${M}${D}T${H}${Min}${S}`;
  }
  return "";
};
//转换日期格式
const YMDHM = function(dateS) {
  if (dateS) {
    let date = new Date(dateS);
    let Y = date.getFullYear();
    let M =
      date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1;
    let D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    let H = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    let Min =
      date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    let S =
      date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    return `${Y}-${M}-${D} ${H}:${Min}`;
  }
  return "";
};
const YMDH = function(dateS) {
  if (dateS) {
    let date = new Date(dateS);
    let Y = date.getFullYear();
    let M =
      date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1;
    let D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    let H = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    return `${Y}-${M}-${D} ${H}时`;
  }
  return "";
};

const dateToStringShort = function(dateS) {
  return dateToString(dateS).substr(0, 10);
};

const fmtTime = function(row, col) {
  let val = row[col.property];
  return dateToString(val);
};

const fmtRole = function(role) {
  let str = "";
  switch (role) {
    case 0:
      str = "管理员";
      break;
    case 1:
      str = "服务商";
      break;
    case 2:
      str = "企业用户";
      break;
    case 3:
      str = "客服";
      break;
  }
  return str;
};

const fmtScale = function(scale) {
  let str = "";
  switch (scale) {
    case 0:
      str = "1 - 50 人";
      break;
    case 1:
      str = "50 - 100 人";
      break;
    case 2:
      str = "100 - 500 人";
      break;
    case 3:
      str = "500 - 2000 人";
      break;
    case 4:
      str = "2000 - 10000人";
      break;
    case 5:
      str = "10000人以上";
      break;
  }
  return str;
};

const fmtBudget = function(num) {
  let str = "";
  switch (num) {
    case 0:
      str = "面议";
      break;
    case 1:
      str = "1万以下";
      break;
    case 2:
      str = "1万到3万";
      break;
    case 3:
      str = "3万到5万";
      break;
    case 4:
      str = "5万以上";
      break;
  }
  return str;
};
const fmtOrderType = function(num) {
  let str = "";
  switch (num) {
    case 0:
      str = "已下单";
      break;
    case 1:
      str = "服务商已通过确认";
      break;
    case 2:
      str = "服务商确认未通过";
      break;
    case 3:
      str = "已取消";
      break;
    case 4:
      str = "已签署合同";
      break;
    case 5:
      str = "实施中";
      break;
    case 6:
      str = "实施完成等待验收";
      break;
    case 7:
      str = "验收通过";
      break;
    case 8:
      str = "验收未通过";
      break;
    case 9:
      str = "平台拒绝结算";
      break;
    case 10:
      str = "订单完成";
      break;
    case 11:
      str = "退款中";
      break;
    case 12:
      str = "已退款";
      break;
    case 13:
      str = "已评价";
      break;
    case 14:
      str = "已投诉";
      break;
  }
  return str;
};

const fmtDemandStatus = function(num) {
  let str = "";
  switch (num) {
    case 0:
      str = "待审核";
      break;
    case 1:
      str = "已通过";
      break;
    case 2:
      str = "已拒绝";
      break;
  }
  return str;
};
const fmtFollowUpStatus = function(num) {
  let str = "";
  switch (num) {
    case 1:
      str = "待跟进";
      break;
    case 2:
      str = "已跟进";
      break;
  }
  return str;
};
const fmtComplaintStatus = function(num) {
  let str = "";
  switch (num) {
    case 0:
      str = "待跟进";
      break;
    case 1:
      str = "已跟进";
      break;
  }
  return str;
};

const fmtSolutionStatus = function(num) {
  let str = "";
  switch (num) {
    case 0:
      str = "已发布";
      break;
    case 1:
      str = "已停用";
      break;
  }
  return str;
};
const fmtRecommendStatus = function(num) {
  let str = "";
  switch (num) {
    case 0:
      str = "轮播图";
      break;
    case 1:
      str = "推荐位";
      break;
  }
  return str;
};

const fmtSolutionDuration = function(num) {
  let str = "";
  switch (num) {
    case 0:
      str = "一周以内";
      break;
    case 1:
      str = "一个月以内";
      break;
    case 2:
      str = "三个月以内";
      break;
    case 3:
      str = "半年以内";
      break;
    case 4:
      str = "一年以内";
      break;
    case 5:
      str = "一年以上";
      break;
  }
  return str;
};

const fmtVerifyStatus = function(num) {
  let str = "";
  switch (num) {
    case 0:
      str = "待审核";
      break;
    case 1:
      str = "审核通过";
      break;
    case 2:
      str = "审核未通过";
      break;
  }
  return str;
};
const fmtDisplayOrder = function(num) {
  let str = "";
  switch (num) {
    case 0:
      str = "推荐位1";
      break;
    case 1:
      str = "推荐位2";
      break;
    case 2:
      str = "推荐位3";
      break;
  }
  return str;
};

const catchError = function(errRes, errorText) {
  if (errorText) {
    this.$message.error(errorText);
  } else {
    if (errRes.code != 403) {
      if (errRes.msg) {
        this.$message.error(errRes.msg.toString());
      } else {
        this.$message.error(errRes);
      }
    } else {
      this.$message.error(errRes);
    }
  }
};
const RMB = function(row, col) {
  if (row[col.property] || row[col.property] == 0) {
    return row[col.property].toFixed(0) + " 元";
  }
  return "";
};

const fmtImgUrl = async function(imgName) {
  return await new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve("http://platform.expeedos.cn/media/" + imgName);
    }, 2000);
  });
};

export default {
  dateToString,
  dateToStringShort,
  fmtTime,
  YMDHM,
  YMDH,
  fmtRole,
  fmtScale,
  catchError,
  RMB,
  fmtBudget,
  fmtOrderType,
  fmtSolutionStatus,
  fmtDemandStatus,
  fmtFollowUpStatus,
  fmtVerifyStatus,
  fmtComplaintStatus,
  fmtSolutionDuration,
  fmtRecommendStatus,
  fmtDisplayOrder,
  fmtImgUrl
};
